import React, {Component} from 'react';
import TabNav from './TabNav';
import Tab from './Tab';
import Titulares from './Titulares';

class DominialDetail extends Component{
    constructor(props) {
        super(props);

        this.state = {
            selected: 'Vehiculo',
        }
        
      }
     
    setSelected = (tab) => {
       this.setState({selected: tab});
    }
   
    render() {
        
        if (typeof this.props.load !== 'undefined' && this.props.load){  
            
            return (
                <>
                    <TabNav tabs={['Vehiculo']} selected={ this.state.selected} setSelected={this.setSelected} load={this.state.isLoaded} /> 
                   
                    <Tab isSelected = {this.state.selected === 'Vehiculo'}  >
                            <div  className="container  mt-5">  
                                <div className="row" > 
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Modelo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 ">
                                        <p className="text-left"><strong>{this.props.item.Data.anioModelo}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Dominio: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.dominio}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Marca codigo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.marcaCodigo}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Marca descripcion: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.marcaDescripcion}</strong></p>                   
                                    </div> 
                                </div>  

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Modelo codigo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.modeloCodigo}</strong></p>                   
                                    </div> 
                                </div> 

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Modelo descripcion &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.modeloDescripcion}</strong></p>                   
                                    </div> 
                                </div> 
                                
                                
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Tipo Codigo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.tipoCodigo}</strong></p>                   
                                    </div> 
                                </div>  

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Tipo descripcion: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.tipoDescripcion}</strong></p>                   
                                    </div> 
                                </div>  
                                                                
                            </div>
                    </Tab>                    
                    
                </>
            )
        }
        else {
            return null;
        }
    }
}

export default DominialDetail;