import React, {useContext} from 'react';
import SearchLicence from '../SearchLicence';
import useToken from '../../token/useToken';
import { useHistory } from "react-router-dom";
import AplicacionContext from '../../context/AplicacionContext';

function Fiscalizacion () {
    const context = useContext(AplicacionContext);
    const history = useHistory();
    const { token, setToken } = useToken();
   
    
    function menu(){
       context.changeApp("Menú de Aplicaciones"); 
       history.push("/menu") 
    }    

    return(
       
        <>
            <SearchLicence token={token} menu={menu} usuario= {context.getUsername()}></SearchLicence>
        </>
    )
}

export default Fiscalizacion;