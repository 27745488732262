import React, {Component} from 'react';
import TabNav from './TabNav';
import Tab from './Tab';

class VtvDetail extends Component{
    constructor(props) {
        super(props);

        this.state = {
            selected: 'Verificacion',
        }
        
      }
     
    setSelected = (tab) => {
       this.setState({selected: tab});
    }

    parseDate = (fechaString) => {
        var parts = fechaString.split("/");
        var dt = new Date(parseInt(parts[2], 10),
                  parseInt(parts[1], 10) - 1,
                  parseInt(parts[0], 10));
        return dt;
    }

    parseDateDMA = (fechaString, separador) => {
        var parts = fechaString.split(separador);
        var dt = parts[2] + "/" + parts[1] + "/" +
                  parts[0]
                  ;
        return dt;
    }

    render() {
        
        if (typeof this.props.load !== 'undefined' && this.props.load){ 
            let reverificacion; 
            let prorroga = null;                   
            if(this.props.item.data.reverificacion){
                reverificacion = <p className="text-left"><strong>SI</strong></p>;
            } else {
                reverificacion =<p className="text-left"><strong>NO</strong></p>;
            }
            if (this.props.item.prorrogaVTV !== null){                 
                if(this.parseDate(this.props.item.prorrogaVTV.fechaVencimientoProrrogada).valueOf() < new Date().valueOf()){
                    prorroga = <div className="row">         
                            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 bg-danger">
                                <p className="text-left text-white">Vto. prorrogada: &nbsp;</p>          
                            </div>  
                            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 bg-danger">
                                <p className="text-left text-white"><strong>{this.props.item.prorrogaVTV.fechaVencimientoProrrogada}</strong></p>                   
                            </div> 
                        </div>  
                } else{             
                    prorroga = <div className="row">         
                            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left " >Vto. prorrogada: &nbsp;</p>          
                            </div>  
                            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <p className="text-left"><strong>{this.props.item.prorrogaVTV.fechaVencimientoProrrogada}</strong></p>                   
                            </div> 
                        </div>  
                }
            }       
            return (
                <>
                    <TabNav tabs={['Verificacion','Vehiculo']} selected={ this.state.selected} setSelected={this.setSelected} load={this.state.isLoaded} /> 
                    <Tab isSelected = {this.state.selected === 'Verificacion'} >
                            <div className="container  mt-5">  
                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Número Oblea: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.numero_oblea}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Verificación: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.fecha_verificacion}</strong></p>                   
                                    </div> 
                                </div>  

                                 <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Vto. original: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.fecha_vencimiento}</strong></p>                   
                                    </div> 
                                </div>         
                                {prorroga}

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Estación : &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.estacion}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Ejercício: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.ejercicio}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Reverificación: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    {reverificacion}                   
                                    </div> 
                                </div> 

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Zona: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.zona}</strong></p>                   
                                    </div> 
                                </div>    
                                
                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Número: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.numero}</strong></p>                   
                                    </div> 
                                </div>  
                                 
                                 <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Tipo Resultado: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.tipo_resultado}</strong></p>                   
                                    </div> 
                                </div> 

                                 <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Id de Turno: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.data.turno_id}</strong></p>                   
                                    </div> 
                                </div>                                   
                                                              
                         
                            </div>
                    </Tab>
                    <Tab isSelected = {this.state.selected === 'Vehiculo'}  >
                            <div  className="container  mt-5">  
                                <div className="row" > 
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Clasificación: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 ">
                                        <p className="text-left"><strong>{this.props.item.data.clasificacion}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Marca: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.marca}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Modelo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.modelo}</strong></p>                   
                                    </div> 
                                </div> 

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Número Chasis: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.numero_chasis}</strong></p>                   
                                    </div> 
                                </div> 
                                
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Número Motor: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.numero_motor}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Matrícula: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.matricula}</strong></p>                   
                                    </div> 
                                </div>  
                                
                            </div>
                    </Tab>
                    {/*<Tab isSelected = {this.state.selected === 'Titular'} >
                            <div className="container mt-5">  
                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Apellido y Nombre: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.titular}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">DNI/CUIT: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.data.dni_cuit}</strong></p>                   
                                    </div> 
                                </div>

                                
                            </div>
                    </Tab>*/}
                    
                    
                </>
            )
        }
        else {
            return null;
        }
    }
}

export default VtvDetail;