//TEST
//export const URL_CONSULTA_USUARIO = 'http://licenciasba.dppsv.gba.gob.ar:9012/api/ConsultaUsuario';
//export const URL_TITULAR_POR_DOMINIO = 'http://licenciasba.dppsv.gba.gob.ar:9012/api/TitularPorDominio';
//export const URL_CONSULTAR_LICENCIA_RUBIDIO = 'http://licenciasba.dppsv.gba.gob.ar:9012/api/ConsultarLicenciaRubidio';
//export const URL_CONSULTA_VTV = 'http://10.1.26.149:9013/api/ConsultaVtv';

//PRODUCCIÓN
export const URL_CONSULTA_USUARIO = 'https://licenciasba.dppsv.gba.gob.ar:9054/api/ConsultaUsuario';
export const URL_TITULAR_POR_DOMINIO = 'https://licenciasba.dppsv.gba.gob.ar:9054/api/TitularPorDominio';
export const URL_CONSULTAR_LICENCIA_RUBIDIO = 'https://licenciasba.dppsv.gba.gob.ar:9054/api/ConsultarLicenciaRubidio';
export const URL_CONSULTA_VTV = 'https://licenciasba.dppsv.gba.gob.ar:9053/api/ConsultaVtv';