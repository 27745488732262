import React, { Component} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import axios from 'axios';
import Messages from './Messages';
import LicenceDetail from './LicenceDetail';
import { FormCheck } from 'react-bootstrap';
import Pie from './Pie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import {URL_CONSULTAR_LICENCIA_RUBIDIO} from '../types/urlTypes'



class SearchLicence extends Component {

  constructor(props){
    super(props);

    this.state = {
      dni: '',
      sexo:'M',
      item: {},
      isLoaded: false,
      status: 0,
      message:'', 
      key: null,
      activarSpinner:null
     //isVerify:false    
    } 
  }  


  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
 }
  
  clear = event => {
    this.setState({
      dni:'',
      sexo:'M',
      item: {},
      isLoaded:false,
      status:0,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      activarSpinner:true
    })
      
      if (this.state.dni === 0 ||this.state.dni === null || this.state.dni === ""
          || this.state.sexo === null || this.state.sexo === ""){
       
       return;
      }

      axios.post(URL_CONSULTAR_LICENCIA_RUBIDIO,
        {        
          "DNI": this.state.dni,       
          "Sexo": this.state.sexo,  
          "token": this.props.token,      
          "usuario": this.props.usuario,
          "SistemaOrigen": "web",
        })
        .then(res =>  {
          console.log(res.data);   
          if(res.data.Status === 1){       
            this.setState({
              item: res.data,
              //status:res.data.Status,              
              status:0,
              isLoaded:true,
              key: Math.random(),
              activarSpinner:false               
            }) 
          } else{
            this.setState({              
              isLoaded:false, 
              item: res.data,
              status:1, 
              activarSpinner:false             
            }) 
          }                 
        })
        .catch (error => {
          this.setState({
            item: {},
            isLoaded:false,
            status:error.response.status, 
            activarSpinner:false            
          })
          console.log(error);
        })
  }

  render() {

    return (
    <>  
    <div className="container" style={{paddingBottom:150, fontFamily:'Encode Sans'}}>
    <Button className="mt-5" style={{backgroundColor:'#00b1c7', borderColor:'#00b1c7'}} onClick={this.props.menu}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Volver al menu</Button>
      <h3 className="mt-5 text-center">Consulta</h3> 
     <Form onSubmit={this.handleSubmit}>
      <FormGroup>
        <Form.Label className="float-left">DNI</Form.Label>
        <Form.Control type="number" placeholder="Ingrese el DNI" name="dni" value={this.state.dni} onChange={this.handleChange.bind(this)} required ></Form.Control>  
      </FormGroup>
      <FormGroup style={{textAlign:'initial'}}>
        <Form.Label>Seleccione sexo</Form.Label>
        <FormCheck>
          <FormCheck.Input type="radio"  name="sexo" value='M' checked={this.state.sexo === 'M'} onChange={this.handleChange.bind(this)}></FormCheck.Input>
          <FormCheck.Label>Masculino</FormCheck.Label>
        </FormCheck>
        <FormCheck >
          <FormCheck.Input type="radio" name="sexo" value='F' checked={this.state.sexo === 'F'} onChange={this.handleChange.bind(this)}></FormCheck.Input>
          <FormCheck.Label>Femenino</FormCheck.Label>
        </FormCheck>    
      </FormGroup>
      <FormGroup>
      <div className="text-center">
        <Button className="col-xs-12 col-lg-4" type="submit" style={{color:'white', backgroundColor: '#00b1c7',borderColor: '#00b1c7',marginTop:15, marginRight:5}}> Buscar </Button>
        <Button onClick={this.clear} className="col-xs-12 col-lg-4" style={{color:'white', backgroundColor: '#00b1c7',borderColor: '#00b1c7',marginTop:15}}> Limpiar </Button>
      </div>      
      </FormGroup>
     </Form>

     {!this.state.activarSpinner ?
        <>
        <LicenceDetail load={this.state.isLoaded} item={this.state.item} key={this.state.key}/>
        <Messages status={this.state.status} text={this.state.item.StatusDescripcion} />
        </>
        :
        <div className="text-center" style={{paddingTop:45}}>
          <Spinner animation="border" role="status" style={{color:'#00b1c7'}}>
              <span className="sr-only">Loading...</span>
          </Spinner>
          <p>Espere por favor</p>
        </div>
        }

     </div>
     <Pie></Pie>
     </>
    )
  }
}
export default SearchLicence;