import React, {Component} from 'react';

class Tab extends Component {
    render(){
        if (this.props.isSelected){
            return (
                <div style={{height:'auto'}}>
                    {this.props.children}
                </div>
            )
        } else {
            return null;
        }
    }
}

export default Tab;