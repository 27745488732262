import React, { useState,useContext,useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';

//import PropTypes from 'prop-types';
import axios from 'axios';
import Messages from './Messages';
import Recaptcha from "react-grecaptcha";
import Pie from './Pie';
import AplicacionContext from '../context/AplicacionContext'
import {useHistory} from 'react-router-dom';
import {URL_CONSULTA_USUARIO} from '../types/urlTypes'




export default function Login({ setToken }) {
        const context = useContext(AplicacionContext);
        const [username, setUserName] = useState();
        const [password, setPassword] = useState();
        const [status, setStatus] = useState();
        const [message, setMessage] = useState('');
        const [captcha, setCaptcha] = useState();
        const [tokenCaptcha, setTokenCaptcha] = useState();
        const history = useHistory();
        
        const saveUsuario = (usuario) => {
            console.log(usuario);
            sessionStorage.setItem('usuario', usuario);                        
          };
        

        const verifyCallback = response =>  {
            setCaptcha({ captcha: !response ? false : true });            
            setTokenCaptcha( !response ? '' : response );
            
        }

        const expiredCallback = response => {
            setCaptcha({ captcha: false })
        }
       
        const handleSubmit = e => {
            e.preventDefault();
            
            if(!captcha){
                setStatus(2);
                setMessage('Verifique el captcha');
                return;
            }
            
            console.log(captcha);
            console.log(tokenCaptcha);

            axios.post(URL_CONSULTA_USUARIO,
              {        
                "usuario": username,       
                "pass": password, 
                "TokenCaptcha": tokenCaptcha,        
              })
            .then(res =>  {
                console.log(res.data);   
                if(res.data.Status === 1){                      
                    let token = { token: res.data.token.RepuestaToken}                  
                    setToken(token);    
                    saveUsuario(username);                                   
                    setStatus(0);
                    context.loginUsuario();
                    context.changeApp("Menú de Aplicaciones");
                    history.push("/menu")
                } else{        
                    setStatus(1);
                    setMessage(res.data.StatusDescripcion);
                    setUserName("");
                    setPassword("");
                    window.grecaptcha.reset();
                    
                }                 
            })
            .catch (error => {                                 
                setStatus(error.response.status); 
                setMessage('Ocurrio un error en la llamada al servicio');                          
                console.log(error);
                window.grecaptcha.reset();
            })
        }

        useEffect(() => {
            if (context.login) {
                history.push("/menu");
            }
        })
        
        return(
        <>
        <AplicacionContext.Consumer>
            {context => (
                <div className="container" style={{paddingBottom:90, fontFamily:'Encode Sans'}}>
                <h3 className="mt-5 text-center">Acceso usuarios</h3>  
                {!context.login ? 
                <>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Form.Label className="float-left">Usuario</Form.Label>
                        <Form.Control type="text" placeholder="Ingrese el usuario" value={username} onChange={e => setUserName(e.target.value)} required></Form.Control>
                    </FormGroup>  
                    <FormGroup>
                        <Form.Label className="float-left">Contraseña</Form.Label>
                        <Form.Control type="password" placeholder="Ingrese la contraseña" value={password} onChange={e => setPassword(e.target.value)} required></Form.Control>
                    </FormGroup>
                
                    <Recaptcha
                        sitekey='6LdFU3caAAAAAPQYrL7pMGhgrNlupkUy-sLQ3cgK'
                        callback={verifyCallback}
                        expiredCallback={expiredCallback}
                        name='captcha'
                        locale="es"                                                    
                        data-theme="white"
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4"
                        style={{padding:0}}
                    /> 
                    <div className="text-center">
                        <Button className="col-xs-12 col-lg-4" type="submit" style={{color:'white', backgroundColor: '#00b1c7',borderColor: '#00b1c7',marginTop:15}}> Ingresar </Button>
                    </div>
                                                           
                </Form> 
                    <div className="mt-5">
                        <Messages status={status} text={message} />
                    </div>
                </>
                : 
                
                <div>Usuario logueado, ir al menu</div>
                
                }                  
              
         </div>
            )}
        </AplicacionContext.Consumer>
        
            
            
        <Pie></Pie>
        
        </>
        
    )
}

//Login.propTypes = {
//    setToken: PropTypes.func.isRequired
//}