import React, {Component} from 'react'

class InfraccionesSacit extends Component {
    constructor(props) {
        super(props)
    }


render() {
    return(
        <>
        <div>
            {this.props.infracciones.map((infraccion, key) => (
            <div className="container  mt-5 border">  
                <div className="row" style={{fontSize:16, backgroundColor: '#838383', color: 'white'}}>         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Nro. causa: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.NumeroCausa}</strong></p>                   
                    </div> 
                </div> 

                <div className="row" style={{marginTop:10}}>         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Estado causa: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.EstadoCausa}</strong></p>                   
                   </div> 
               </div> 

                <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Fecha infracción: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.FechaInfraccion}</strong></p>                   
                   </div> 
               </div>  

               <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Localidad de la infracción: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.LocalidadInfraccion}</strong></p>                   
                   </div> 
               </div>

               <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Lugar de la infracción: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.LugarInfraccion}</strong></p>                   
                   </div> 
               </div>

               <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Normativa: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    {infraccion.Normativa.map((normativa, key) => (
                        <p className="text-left"><strong>{normativa.Item}</strong></p>
                    ))}             
                   </div> 
               </div>

               <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Juzgado: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.Juzgado}</strong></p>                   
                   </div> 
               </div> 
       
               <div className="row">         
                   <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">Domicilio del Juzgado: &nbsp;</p>          
                   </div>  
                   <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p className="text-left"><strong>{infraccion.JuzgadoDomicilio}</strong></p>                   
                   </div> 
               </div> 

            </div>
            ))}
        </div>
        </>
    )
}
}

export default InfraccionesSacit;