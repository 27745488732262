import React, {Component} from 'react';

class Titulares extends Component{
    constructor(props) {
        super(props);

        this.state = {
           
        }
        
      }  
 
  
      render() {
        return (<div>
        {this.props.titulares.map ((titular, index) => (
            <div className="container  mt-5 border">  
                <div className="row" style={{fontSize:16, backgroundColor: '#838383', color: 'white'}}>         
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                    <p className="text-left">Nombre: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <p className="text-left"><strong>{titular.TitNom}</strong></p>                   
                    </div> 
                </div>

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                    <p className="text-left">Nro. Documento: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <p className="text-left"><strong>{titular.TitDocumento}</strong></p>                   
                    </div> 
                </div>

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                    <p className="text-left">CUIT: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <p className="text-left"><strong>{titular.TitCuit}</strong></p>                   
                    </div> 
                </div> 
            
                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                    <p className="text-left">Inicio Titularidad: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <p className="text-left"><strong>{titular.InicioTitularidad}</strong></p>                   
                    </div> 
                </div>  

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                    <p className="text-left">Porcentaje: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <p className="text-left"><strong>{titular.TitPorcentaje}%</strong></p>                   
                    </div> 
                </div>  

             </div>
        ))}
        </div>);
    }
      
   
    
}
export default Titulares;