import React, {useContext} from 'react';
import Pie from '../Pie';
import AplicacionContext from '../../context/AplicacionContext'
import {useHistory} from 'react-router-dom';
import useToken from '../../token/useToken';
import removeToken from '../../token/removeToken';
import SearchVtv from '../../components/SearchVtv';


function Vtv () {
    
    const history = useHistory();
    const { token, setToken } = useToken();
    const context = useContext(AplicacionContext);

    function menu(){
        context.changeApp("Menú de Aplicaciones"); 
        history.push("/menu") 
     }  
    
    
    return(
        <>
            <SearchVtv token={token} menu={menu} usuario= {context.getUsername()}></SearchVtv>
        </>
    );
}

export default Vtv;