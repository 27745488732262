import React, { Component} from 'react';

class Messages extends Component {
    constructor(props) {
        super(props);
       
      }

    render() {
        if (typeof this.props.status === 'undefined' || this.props.status === 0){
            return null;
        }

        if (this.props.status === 1) {
            var mensaje = this.props.text;
            if(mensaje.includes("timeout")){
                return <div className="alert alert-warning" role="alert">
                            Consulta momentaneamente fuera de servicio.
                        </div>;
            } else {
                return <div className="alert alert-warning" role="alert">
                        {this.props.text}
                    </div>;
            }
        }

        if(this.props.status !== 0 && this.props.status !== 1) {
            return <div className="alert alert-primary" role="alert">
                      Se hay producido un error en la llamada al servicio.
                    </div>;
        }

        return null;
    };
}
export default Messages;
