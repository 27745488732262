import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {faCopyright} from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Pie() {

    var version = "3.0.0.0";

    return(
        <div>
            <Navbar style={{backgroundColor:'#838383', marginBottom:25, marginTop:30}} sticky="top">
            <div className="container">
                <Nav className="mr-auto" style={{color:'white'}}>
                <FontAwesomeIcon style={{height:'auto'}} icon={faCopyright}></FontAwesomeIcon>&nbsp;FISCALIZARBA {version}
                </Nav>
                <Nav style={{color:'white'}}>
                    <FontAwesomeIcon style={{height:'auto'}} icon={faReact}></FontAwesomeIcon>&nbsp;React
                </Nav>
            </div>
            </Navbar>
            <div className="container" style={{paddingLeft:0, marginBottom:25}}>
            <div className="text-left">Ministerio de Infraestructura y Servicios Públicos | Dirección Provincial de Política y Seguridad Vial | Buenos Aires Provincia</div>
            </div>
        </div>    
    );
}

export default Pie;
