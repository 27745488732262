import React, {Component} from 'react';
import TabNav from './TabNav';
import Tab from './Tab';
import './LicenceDetail.css';
import Inhabilitaciones from './Inhabilitaciones';
import Alert from 'react-bootstrap/Alert'

class LicenceDetail extends Component{
    constructor(props) {
        super(props);

        this.state = {
            selected: 'Personal',
        }
        
      }
     
    setSelected = (tab) => {
       this.setState({selected: tab});
    }

    transformImage = (base64) => {
        if(base64 !== null) {
            var image = new Image();
            image.src = 'data:image/png;base64,' +base64;
            return image.currentSrc;
        }
        else {
            return '';
        }
    }

    parseDateDMA = (fechaString, separador) => {
        var parts = fechaString.split(separador);
        var dt = parts[1] + "/" + parts[0] + "/" +
                  parts[2]
                  ;
        return dt;
    }
   
    render() {
        
        if (typeof this.props.load !== 'undefined' && this.props.load){   
            var solapas = ['Personal','Licencia','Biometricos', 'Autorizante']; 
                                  
            let prorroga = null; 
            let retencion = null;            
            let inhabilitacion = null;
            let leyenda = '';
            let errorMsg = [];
            let error = '';

            console.log()

            if (this.props.item.Data.Prorroga !== null){                                            
                if(new Date().valueOf() > new Date(this.props.item.Data.Prorroga.FechaVencimientoProrrogada).valueOf()){
                    prorroga = <div className="row">         
                            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 bg-danger">
                                <p className="text-left text-white">Fecha de vencimiento prorrogada: &nbsp;</p>          
                            </div>  
                            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 bg-danger">
                                <p className="text-left text-white"><strong>{this.props.item.Data.Prorroga.FechaVencimientoProrrogada}</strong></p>                   
                            </div> 
                        </div>  
                } else{             
                    prorroga = <div className="row">         
                            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left " >Fecha de vencimiento prorrogada: &nbsp;</p>          
                            </div>  
                            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <p className="text-left"><strong>{this.props.item.Data.Prorroga.FechaVencimientoProrrogada}</strong></p>                   
                            </div> 
                        </div>  
                }
            } 

            let infraccionesSacitError = false;
            if (this.props.item.Data.InfraccionesSacit != null){
                if (this.props.item.Data.InfraccionesSacit.Infracciones == null){
                    infraccionesSacitError = true
                }
            }


            if (this.props.item.Data.Inhabilitacio.ErrrorInhabilitacion !== null || infraccionesSacitError){

                if (this.props.item.Data.Inhabilitacio.ErrrorInhabilitacion !== null){               
                    errorMsg = [...errorMsg, <p> Error Inhabilitaciones:  {this.props.item.Data.Inhabilitacio.ErrrorInhabilitacion.ErrorDescripcion}</p>]
                }
                else {
                    errorMsg = [...errorMsg, <p> Error Inhabilitaciones:  {this.props.item.Data.InfraccionesSacit.ErrorInfracciones.ErrorDescripcion}</p>]
                }

            } else if (this.props.item.Data.Inhabilitacio.Inhabilitaciones !== null || this.props.item.Data.InfraccionesSacit !== null){
                solapas.push('Inhabilitaciones') ; 
                leyenda = <div >
                            <Alert variant='danger'><strong>La licencia posee inhabilitaciones</strong></Alert>
                        </div>
                inhabilitacion = <Tab isSelected = {this.state.selected === 'Inhabilitaciones'} > 
                                <Inhabilitaciones inhabilitaciones = {this.props.item.Data.Inhabilitacio.Inhabilitaciones} infracciones={this.props.item.Data.InfraccionesSacit}/> 
                            </Tab> 
            }

            if (this.props.item.Data.RetencioLicencia.ErrorRetencion !== null){                
                errorMsg = [...errorMsg, <p>  Error Retencion: {this.props.item.Data.RetencioLicencia.ErrorRetencion.ErrorDescripcion}</p>]
               
            } else  if (this.props.item.Data.RetencioLicencia.DataRetencion !== null){
                  solapas.push('Retencion') ;
                  leyenda = <div >
                            <Alert variant='danger'><strong>Licencia con retención</strong></Alert>
                        </div>
                   retencion = <Tab isSelected = {this.state.selected === 'Retencion'}  >
                            <div className="container  mt-5">  
                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Direccion Juzgado: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10"> 
                                <p className="text-left"><strong>{this.props.item.Data.RetencioLicencia.DataRetencion.DescripcionJuzgado}</strong></p>                                                                  
                                </div> 
                             </div>

                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Estado Causa: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <p className="text-left"><strong>{this.props.item.Data.RetencioLicencia.DataRetencion.EstadoCausa}</strong></p>                                
                                </div> 
                             </div>

                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Juzgado: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <p className="text-left"><strong>{this.props.item.Data.RetencioLicencia.DataRetencion.Juzgado}</strong></p>                                
                                </div> 
                             </div>

                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Nro. Causa: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <p className="text-left"><strong>{this.props.item.Data.RetencioLicencia.DataRetencion.NroCausa}</strong></p>                                
                                </div> 
                             </div>
                            </div>
                    </Tab>
            }
            if (errorMsg.length > 0){
                error =  <div >
                    <Alert variant='danger'><strong>{errorMsg}</strong></Alert>
                </div>             
            }
            let navBar =  <TabNav tabs={solapas} selected={ this.state.selected} setSelected={this.setSelected} load={this.state.isLoaded} /> ;
           

            return (
                <>
                    {leyenda}
                    {error}
                    {navBar}
                    <Tab isSelected = {this.state.selected === 'Personal'}  >
                            <div  className="container  mt-5">  
                                <div className="row" > 
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Nombres: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 ">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.Nombres}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Apellidos: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.Apellidos}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Factor: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.Factor}</strong></p>                   
                                    </div> 
                                </div> 

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Grupo: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.Grupo}</strong></p>                   
                                    </div> 
                                </div> 
                                
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Nacionalidad: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.Nacionalidad}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Donante: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.Persona.SiDonante}</strong></p>                   
                                    </div> 
                                </div>  
                                
                            </div>
                    </Tab>
                    <Tab isSelected = {this.state.selected === 'Licencia'} >
                            <div className="container mt-5">  
                            <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Fecha desde: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.FechaDesde}</strong></p>                   
                                    </div> 
                                </div> 

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Fecha de vencimiento original impresa: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.FechaVencimiento}</strong></p>                   
                                    </div> 
                                </div> 
                                {prorroga}
                                
                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Numero: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.Numero}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Categoria: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.Categoria}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Clases: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.CodigosClases}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Descripción Clases: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.DescripcionClases}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Direccion: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.Direccion}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Nro de Tramite: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.NumeroTramite}</strong></p>                   
                                    </div> 
                                </div>   

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Observaciones: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.Observaciones}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row" style={{marginBottom:25}}>         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                        <p className="text-left">Restricciones: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                        <p className="text-left"><strong>{this.props.item.Data.InfoLicenia.Restricciones}</strong></p>                   
                                    </div> 
                                </div>
                            
                            </div>
                    </Tab>
                    <Tab isSelected = {this.state.selected === 'Biometricos'} >
                            <div className="container  mt-5">  
                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Foto: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">                                  
                                    <img src={this.transformImage(this.props.item.Data.Biometricos.Foto)} width="180" height="200" alt="Foto" />                                     
                                </div> 
                             </div>

                             <div className="row"> 
                                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                <p className="text-left">Firma: &nbsp;</p>          
                                </div>  
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                <img src={this.transformImage(this.props.item.Data.Biometricos.Firma)} width="200" height="200" alt="Firma" />                
                                </div> 
                             </div>
                            </div>
                    </Tab>
                    <Tab isSelected = {this.state.selected === 'Autorizante'} >
                            <div className="container  mt-5">  
                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Apellido Funcionario: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.Data.EnteAutorizante.ApellidoFuncionario}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Nombre Funcionario : &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.Data.EnteAutorizante.NombreFuncionario}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Municipio: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.Data.EnteAutorizante.MunicipioFuncionario}</strong></p>                   
                                    </div> 
                                </div>

                                <div className="row">         
                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
                                    <p className="text-left">Provincia: &nbsp;</p>          
                                    </div>  
                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <p className="text-left"><strong>{this.props.item.Data.EnteAutorizante.Provincia}</strong></p>                   
                                    </div> 
                                </div>                               
                         
                            </div>
                    </Tab>
                    {retencion}
                    {inhabilitacion}

                </>
            )
        }
        else {
            return null;
        }
    }
}

export default LicenceDetail;