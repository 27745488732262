import React, {Component} from "react";
import AplicacionContext from './AplicacionContext';

class GlobalState extends Component {
    state = {
        login: sessionStorage.getItem("login") || false,
        app: sessionStorage.getItem("app") || "Bienvenido",       
    }

    login = () => {
        this.setState({
            login: true
        });
        sessionStorage.setItem("login",true);
        sessionStorage.setItem("app","Bienvenido")
    }

    logout = () => {
        this.setState({
            login: false,
            app: "Bienvenido"
        });
        sessionStorage.removeItem("login");
        sessionStorage.removeItem("app");
    }

    changeApp = (aplicacion) => {
        this.setState({
            app: aplicacion
        });
        sessionStorage.setItem("app",aplicacion);
    }

    getUsername = () => {
        return sessionStorage.getItem("usuario") || ""
    }

    render() {
        return (
            <AplicacionContext.Provider
            value={{
                login: this.state.login,
                app: this.state.app,
                getUsername:this.getUsername,
                loginUsuario: this.login,
                logoutUsuario: this.logout,
                changeApp: this.changeApp
                }}
            >
                {this.props.children}
            </AplicacionContext.Provider>
        )
    }
}

export default GlobalState;