import React, { useState,useContext } from 'react';
import institucional2 from './institucional2.svg';
import './App.css';
import Login from './components/Login'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import SearchLicence from './components/SearchLicence'
import useToken from './token/useToken';
import removeToken from './token/removeToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {faCopyright} from '@fortawesome/fontawesome-free-regular';
import Menu from './components/Menu';
import AplicacionContext from './context/AplicacionContext';
import {useHistory} from 'react-router-dom';



function App() {
  const { token, setToken } = useToken();
  const history = useHistory();
  const context = useContext(AplicacionContext);

  function logout() {
    removeToken();
    setToken(false);
    context.logoutUsuario();
    history.push("/inicio");
  }  

  return (
    <>
    <AplicacionContext.Consumer>
    {context => (
      <div className="App">
      <header className="App-header">
      <div className="container">
        <div className="row" >  
          <div className="col-md-12 col-lg-3 text-center" style={{paddingTop:15}} >
            FISCALIZAR<strong>BA</strong>
           
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-2"></div>
          <div className="col-md-12 col-lg-3 text-center" style={{paddingTop:15, paddingBottom:15}} >
            <a href="http://www.gob.gba.gov.ar/portal/index.php" title="Ministerio de Jefatura de Gabinete de Ministros - Buenos Aires La Provincia" className="logo-wrap" >
				      <img src={institucional2} width="240" height="90" alt="institucional"  />
            </a>
          </div>
        </div>
      </div>
    </header>


    <Navbar style={{backgroundColor:'#00b1c7'}} variant="dark" sticky="top">
      <div className="container">
         <Navbar.Brand>{context.app}</Navbar.Brand>    
        <Nav className="mr-auto">
          </Nav>
          {context.login &&
            <Nav.Link style={{color:'white'}} onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon> Salir</Nav.Link>
          }
          
      </div>
    </Navbar>

    </div>
    )}

    </AplicacionContext.Consumer>
    </>
  );
}

export default App;
