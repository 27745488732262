import React, {useContext} from 'react';
import SearchDominial from '../SearchDominial';
import useToken from '../../token/useToken';
import { useHistory } from "react-router-dom";
import AplicacionContext from '../../context/AplicacionContext';

function Dominial () {
    const history = useHistory();
    const { token, setToken } = useToken();
    const context = useContext(AplicacionContext);

    function menu(){
       context.changeApp("Menú de Aplicaciones"); 
       history.push("/menu") 
    }    

    return(
        <>
            <SearchDominial token={token} menu={menu} usuario= {context.getUsername()}></SearchDominial>
        </>
    )
}

export default Dominial;