import React, { Component} from 'react';
import '../App.css';

class TabNav extends Component {    
 
    render() {                  
            return <div>
                  <hr/>
                  <ul className="nav nav-tabs" >
                    {
                      this.props.tabs.map(tab => {
                        let active = (tab === this.props.selected ? 'active' : '');
                        if (active == 'active'){
                          if ((this.props.selected == 'Inhabilitaciones') || (this.props.selected == 'Retencion')){
                            active = 'active retenidoInhabilitado'
                          }
                        }
                        return (
                          <li className="nav-item" key = {tab}>
                            <div className={ "activo nav-link active" + active} style={{backgroundColor: ((tab == 'Inhabilitaciones') || (tab == 'Retencion')) ? '#f8d7da' : null, color: ((tab == 'Inhabilitaciones') || (tab == 'Retencion')) ? '#721c24' : null}} onClick={ () => this.props.setSelected(tab)}>
                              {tab}
                            </div>
                          </li>
                        )
                      }

                      )
                    }
                  </ul>
                  {this.props.children}          
              </div>                      
    };
}
export default TabNav;
