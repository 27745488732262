import React, { Component} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import axios from 'axios';
import Messages from './Messages';
import VtvDetail from './VtvDetail';
import Pie from './Pie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import {URL_CONSULTA_VTV} from '../types/urlTypes'



class SearchVtv extends Component {

  constructor(props){
    super(props);

    this.state = {
      dominio: '',     
      item: {},
      isLoaded: false,
      status: 0,
      message:'', 
      key: null,
      activarSpinner:null
     //isVerify:false    
    } 
  }  

  handleChange(e) {
    e.target.value =  e.target.value.toUpperCase();
    this.setState({ [e.target.name] : e.target.value });
 }
  
  clear = event => {
    this.setState({
      dominio:'',    
      item: {},
      isLoaded:false,
      status:0,
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      activarSpinner:true
    })
      
      if (this.state.dominio === 0 ||this.state.dominio === null || this.state.dominio === ""){
       
       return;
      }
      
      axios.post(URL_CONSULTA_VTV,
        {        
          "dominio": this.state.dominio,                  
          "token": this.props.token,    
          "usuario": this.props.usuario,
          "SistemaOrigen": "web"    
        })
        .then(res =>  {
          console.log(res.data);   
          if(res.data.status === 10){       
            this.setState({
              item: res.data,
              //status:res.data.Status,              
              status:0,
              isLoaded:true,
              key: Math.random(),
              activarSpinner:false               
            }) 
          } else{
            this.setState({              
              isLoaded:false, 
              item: res.data,
              status:1,
              activarSpinner:false              
            }) 
          }                 
        })
        .catch (error => {
          this.setState({
            item: {},
            isLoaded:false,
            status:error.response.status, 
            activarSpinner:false            
          })
          console.log(error);
        })
       
  }

  render() {

    return (
    <>  
    <div className="container" style={{paddingBottom:150, fontFamily:'Encode Sans'}}>
    <Button className="mt-5" style={{backgroundColor:'#00b1c7', borderColor:'#00b1c7'}} onClick={this.props.menu}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Volver al menu</Button>  
      <h3 className="mt-5 text-center">Consulta</h3> 
     <Form onSubmit={this.handleSubmit}>
      <FormGroup>
        <Form.Label className="float-left">Dominio</Form.Label>
        <Form.Control type="text" placeholder="Ingrese el dominio" name="dominio" value={this.state.dominio} onChange={this.handleChange.bind(this)} required ></Form.Control>  
      </FormGroup>      
      <FormGroup>
      <div className="text-center">
        <Button className="col-xs-12 col-lg-4" type="submit" style={{color:'white', backgroundColor: '#00b1c7',borderColor: '#00b1c7',marginTop:15, marginRight:5}}> Buscar </Button>
        <Button onClick={this.clear} className="col-xs-12 col-lg-4" style={{color:'white', backgroundColor: '#00b1c7',borderColor: '#00b1c7',marginTop:15}}> Limpiar </Button>
      </div>
      </FormGroup>
     </Form>

     {!this.state.activarSpinner ?
        <>  
        <VtvDetail load={this.state.isLoaded} item={this.state.item} key={this.state.key}/>
        <Messages status={this.state.status} text={this.state.item.statusDescripcion} />
        </>
        :
        <div className="text-center" style={{paddingTop:45}}>
          <Spinner animation="border" role="status" style={{color:'#00b1c7'}}>
              <span className="sr-only">Loading...</span>
          </Spinner>
          <p>Espere por favor</p>
        </div>
        }

     </div>
     <Pie></Pie>
     </>
    )
  }
}
export default SearchVtv;