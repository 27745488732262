import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {Link, Route, Router, Redirect } from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import Login from './components/Login';
import Menu from './components/Menu';
import Inicio from './components/pages/Inicio';
import Vtv from './components/pages/Vtv';
import Dominial from './components/pages/Dominial';
import GlobalState from './context/GlobalState';
import Fiscalizacion from './components/pages/Fiscalizacion';

//URL API DE TEST
//axios.defaults.baseURL='http://licenciasba.dppsv.gba.gob.ar:9012/api/';

//URL API DE PROD
//axios.defaults.baseURL='https://api-licencias.dppsv.gba.gob.ar/api/';


ReactDOM.render(
  <GlobalState>
    <BrowserRouter>
      <Route path='/' component={App} />
      <Route path='/inicio' component={Inicio} />
      <Route path='/vtv' component={Vtv} />
      <Route path='/dominial' component={Dominial} />
      <Route path='/menu' component={Menu} />
      <Route path='/login' component={Login}></Route>    
      <Route path='/fiscalizacion' component={Fiscalizacion}></Route>
      <Redirect from="/" to="/inicio"></Redirect>   
    </BrowserRouter>
  </GlobalState>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
