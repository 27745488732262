import React, { useState,useContext } from 'react';
import Card from 'react-bootstrap/Card'
import Pie from './Pie'
import Button from 'react-bootstrap/Button'
import '../App.css'
import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import AplicacionContext from '../context/AplicacionContext';

function Menu () {

    const history = useHistory();
    const context = useContext(AplicacionContext);

    function fiscalizacion () {
      context.changeApp("Consulta Licencia");
      history.push("/fiscalizacion");
    }

    function vtv () {
      context.changeApp("Consulta VTV");
      history.push("/vtv");
    }

    function dominial () {
      context.changeApp("Consulta Patente");
      history.push("/dominial");
    }

    return(
    <>  
    <div className="container">   
      <div className="row" style={{fontFamily:'Encode Sans'}}>   
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <Card style={{marginTop:30, transition:'transform .2s', minHeight:220}} className="zoom-page">
        <Card.Header className="text-center" style={{fontSize:26, backgroundColor: '#00b1c7', color: 'white'}}>Consulta Licencia</Card.Header>
          <Card.Body className="text-center">
            <Card.Text>
              Acceda a la Consulta de Licencias
            </Card.Text>
            <Button style={{backgroundColor:'#00b1c7', borderColor:'#00b1c7'}} onClick={fiscalizacion}>Acceder</Button>
          </Card.Body>
        </Card>
        </div>
        {<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <Card style={{marginTop:30, transition:'transform .2s', minHeight:220}} className="zoom-page">
        <Card.Header className="text-center" style={{fontSize:26, backgroundColor: '#00b1c7', color: 'white'}}>Consulta Patente</Card.Header>
          <Card.Body className="text-center">            
            <Card.Text>
            Acceda a la Consulta de Control de Patente del Vehículo
            </Card.Text>
            <Button style={{backgroundColor:'#00b1c7', borderColor:'#00b1c7'}} onClick={dominial}>Acceder</Button>
          </Card.Body>
        </Card>
        </div>}
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <Card style={{marginTop:30, transition:'transform .2s', minHeight:220}} className="zoom-page">
        <Card.Header className="text-center" style={{fontSize:26, backgroundColor: '#00b1c7', color: 'white'}}>Consulta VTV</Card.Header>
          <Card.Body className="text-center">
            <Card.Text>
              Acceda a la Consulta de VTV
            </Card.Text>
            <Button style={{backgroundColor:'#00b1c7', borderColor:'#00b1c7'}} onClick={vtv}>Acceder</Button>
          </Card.Body>
        </Card>
        </div>
      </div> 
    </div>
    <Pie></Pie>
    </>
     
    );
}

export default Menu;
