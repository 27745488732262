import React, {Component} from 'react';
import InfraccionesSacit from './inhabilitaciones/InfraccionesSacit';
import Inhabilitado from './inhabilitaciones/Inhabilitado';

class Inhabilitaciones extends Component{
    constructor(props) {
        super(props);

      }  
 
      render() {
        if (this.props.inhabilitaciones !== null && this.props.infracciones !== null) return(<><Inhabilitado inhabilitaciones = {this.props.inhabilitaciones}/><InfraccionesSacit infracciones={this.props.infracciones.Infracciones}/></>);  
        if (this.props.inhabilitaciones !== null) return <Inhabilitado inhabilitaciones = {this.props.inhabilitaciones}/>
        if (this.props.infracciones !== null) return <InfraccionesSacit infracciones={this.props.infracciones.Infracciones}/>
    }
      
   
    
}
export default Inhabilitaciones;