import React from 'react';
import useToken from '../../token/useToken';
import removeToken from '../../token/removeToken';
import Login from '../Login'
import SearchLicence from '../SearchLicence';

function Inicio () {
    const { token, setToken } = useToken();

    return(
        <>
            <Login setToken={setToken} /> 
        </>
    );
}

export default Inicio;