import React, {Component} from 'react'

class Inhabilitado extends Component {
    constructor(props) {
        super(props)
    }


render() {
    let clasesRestringidas = ""   
    this.props.inhabilitaciones.map((inhabilitacion) => {
        let aux= /,/gi;
        let aux1 = inhabilitacion.ClasesRestringidas
        clasesRestringidas = aux1.replace(aux," ");
        }
    );
    return(
        <div>
        {this.props.inhabilitaciones.map ((inhabilitacion, index) => (
            <div className="container  mt-5 border">  
                 <div className="row" style={{fontSize:16, backgroundColor: '#838383', color: 'white'}}>         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Nro. causa: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.NumeroCausa}</strong></p>                   
                    </div> 
                </div>  

                <div className="row" style={{marginTop:10}}>         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Clases restringidas: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{clasesRestringidas}</strong></p>                   
                    </div> 
                </div>

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Ente emisor: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.EnteEmisor} ({inhabilitacion.Localidad})</strong></p>                   
                    </div> 
                </div>

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Fecha desde: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.FechaDesde}</strong></p>                   
                    </div> 
                </div> 
            
                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Fecha hasta: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.Fechahasta}</strong></p>                   
                    </div> 
                </div>  
                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                    <p className="text-left">Sancion: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.Sancion}</strong></p>                   
                    </div> 
                </div> 

                <div className="row">         
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <p className="text-left">Tipo antecedente: &nbsp;</p>          
                    </div>  
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <p className="text-left"><strong>{inhabilitacion.TipoAntecedente}</strong></p>                   
                    </div> 
                </div>  

             </div>
        ))}
        </div>
    )
}
}

export default Inhabilitado;